<template>
  <div>
    <location />
    <div class="page">
      <tab />
      <div class="tab-content wrap-1200" id="pills-tabContent">
        <div
          class="tab-pane fade show active championship condition"
          id="pills-2"
          role="tabpanel"
          aria-labelledby="pills-2-tab"
        >
          <div class="tourn-card mt-3 mb-5">
            <div class="card flex-md-row align-items-md-center">
              <div class="col-md-4 card-img-wrapper order-md-2">
                <img
                  :src="
                    tournamentinfo.tourbinary &&
                    tournamentinfo.tourbinary[0] &&
                    tournamentinfo.tourbinary[0].filepath | get_img
                  "
                />
              </div>
              <div class="card-body order-md-1 ps-md-5">
                <span class="badge rounded-pill">{{
                  tournament_type(tournamentinfo.tourtype_fk)
                }}</span>
                <h4 class="card-title lh-sm text-white">
                  {{ tournamentinfo.title }}
                </h4>
                <p class="info">{{ tournamentinfo.description }}</p>
                <small class="text-date">
                  <!-- 서비스 시간 -->
                  {{
                    tournamentinfo.timezone_startdate &&
                    tournamentinfo.timezone_startdate.timezone_common
                      | dateformat("YYYY.MM.DD")
                  }}
                  ~
                  {{
                    tournamentinfo.timezone_startdate &&
                    tournamentinfo.timezone_enddate.timezone_common
                      | dateformat("YYYY.MM.DD")
                  }}
                </small>
              </div>
            </div>
          </div>
          <b-form
            @submit="onSubmit"
            class="filter-group row row-cols-1 row-cols-md-4 g-3"
          >
            <div class="col">
              <div class="input-group">
                <select
                  class="form-select"
                  aria-label="Select one"
                  name="gender"
                  v-model="searchData.gender"
                >
                  <option value="0" :selected="searchData.gender === 0">
                    {{ $t("filter.common.total") }}
                  </option>
                  <option value="1" :selected="searchData.gender === 1">
                    {{ $t("filter.common.male") }}
                  </option>
                  <option value="2" :selected="searchData.gender === 2">
                    {{ $t("filter.common.female") }}
                  </option>
                </select>
                <button
                  class="btn btn-primary btn-md icon-search"
                  type="submit"
                ></button>
              </div>
            </div>
            <div class="col flex-fill">
              <ul class="tab-sub">
                <li>
                  <a
                    @click="change_type(1)"
                    :class="{ on: searchData.scoretype == 1 }"
                    >{{ $t("button.common.stroke") }}</a
                  >
                </li>
                <li>
                  <a
                    @click="change_type(2)"
                    :class="{ on: searchData.scoretype == 2 }"
                    >{{ $t("button.common.stroke_handicap") }}</a
                  >
                </li>
                <li>
                  <a
                    @click="change_type(3)"
                    :class="{ on: searchData.scoretype == 3 }"
                    >{{ $t("button.common.new_perio") }}</a
                  >
                </li>
                <li>
                  <a
                    @click="change_type(4)"
                    :class="{ on: searchData.scoretype == 4 }"
                    >{{ $t("button.common.net_score") }}</a
                  >
                </li>
              </ul>
            </div>
          </b-form>

          <div class="row g-0 myrank">
            <div class="col-6 col-md-3">
              <ul class="box">
                <li>
                  <span class="rounded-circle bg-purple">
                    <i class="material-icons">military_tech</i>
                  </span>
                </li>
                <li>
                  <h2>
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      rankInfo.tournamentrankinfo.rank | comma
                    }}<small>/{{ total_cnt || 0 }}</small>
                  </h2>
                  <p>{{ $t("text.common.my_ranking") }}</p>
                </li>
              </ul>
            </div>

            <div class="col-6 col-md-3">
              <ul class="box">
                <li>
                  <span class="rounded-circle bg-pink">
                    <i class="material-icons">scoreboard</i>
                  </span>
                </li>
                <li>
                  <h2
                    v-if="
                      searchData.scoretype == '1' || searchData.scoretype == '2'
                    "
                  >
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      Math.sign(
                        rankInfo.tournamentrankinfo &&
                          rankInfo.tournamentrankinfo.strokelastscore
                      ) > 0
                        ? "+"
                        : ""
                    }}
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      rankInfo.tournamentrankinfo.strokelastscore
                    }}
                  </h2>

                  <h2 v-if="searchData.scoretype == '3'">
                    {{
                      rankInfo &&
                      rankInfo.tournamentcourse &&
                      Math.sign(
                        rankInfo.tournamentcourse &&
                          rankInfo.tournamentcourse.newperioscore
                      ) > 0
                        ? "+"
                        : ""
                    }}
                    {{
                      (rankInfo &&
                        rankInfo.tournamentcourse &&
                        rankInfo.tournamentcourse.newperioscore) | toFixed
                    }}
                  </h2>
                  <h2 v-if="searchData.scoretype == '4'">
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      Math.sign(
                        rankInfo.tournamentrankinfo &&
                          rankInfo.tournamentrankinfo.avghandicapscore -
                            rankInfo.tournamentrankinfo.base_par
                      ) > 0
                        ? "+"
                        : ""
                    }}
                    {{
                      (rankInfo &&
                        rankInfo.tournamentrankinfo &&
                        rankInfo.tournamentrankinfo.avghandicapscore -
                          rankInfo.tournamentrankinfo.base_par) | toFixed
                    }}
                  </h2>
                  <p
                    v-if="
                      searchData.scoretype == '1' || searchData.scoretype == '2'
                    "
                  >
                    {{ $t("text.common.course_score") }}
                  </p>

                  <p v-if="searchData.scoretype == '3'">
                    {{ $t("text.common.score_new_perio") }}
                  </p>
                  <p v-if="searchData.scoretype == '4'">
                    {{ $t("text.common.score_hdcp") }}
                  </p>
                </li>
              </ul>
            </div>

            <div class="col-6 col-md-3">
              <ul class="box">
                <li>
                  <span class="rounded-circle bg-cyan">
                    <i class="material-icons">emoji_events</i>
                  </span>
                </li>
                <li>
                  <h2 v-if="searchData.scoretype == '1'">
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      Math.sign(
                        rankInfo.tournamentrankinfo &&
                          rankInfo.tournamentrankinfo.avg_score
                      ) > 0
                        ? "+"
                        : ""
                    }}
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      rankInfo.tournamentrankinfo.avg_score
                    }}
                  </h2>
                  <h2 v-if="searchData.scoretype == '2'">
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      rankInfo.tournamentrankinfo.low_net > 0
                        ? "+"
                        : ""
                    }}
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      rankInfo.tournamentrankinfo.low_net
                    }}
                  </h2>
                  <h2 v-if="searchData.scoretype == '3'">
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      Math.sign(
                        rankInfo.tournamentrankinfo &&
                          rankInfo.tournamentrankinfo.newperiolastscore
                      ) > 0
                        ? "+"
                        : ""
                    }}
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      rankInfo.tournamentrankinfo.newperiolastscore
                    }}
                  </h2>
                  <h2 v-if="searchData.scoretype == '4'">
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      Math.sign(
                        rankInfo.tournamentrankinfo &&
                          rankInfo.tournamentrankinfo.avghandicapscore -
                            rankInfo.tournamentrankinfo.base_par
                      ) > 0
                        ? "+"
                        : ""
                    }}
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      (rankInfo.tournamentrankinfo.avghandicapscore -
                        rankInfo.tournamentrankinfo.base_par)
                        | toFixed
                    }}
                  </h2>
                  <p v-if="searchData.scoretype == '1'">
                    {{ $t("text.membership.avg_score") }}
                  </p>
                  <p v-if="searchData.scoretype == '2'">
                    {{ $t("title.common.low_net_score") }}
                  </p>
                  <p v-if="searchData.scoretype == '3'">
                    {{ $t("text.common.last_score_new_perio") }}
                  </p>
                  <p v-if="searchData.scoretype == '4'">
                    {{ $t("text.common.last_score_hdcp") }}
                  </p>
                </li>
              </ul>
            </div>

            <div class="col-6 col-md-3">
              <ul class="box">
                <li>
                  <span class="rounded-circle bg-orange">
                    <i class="material-icons">flag</i>
                  </span>
                </li>
                <li>
                  <h2>
                    {{
                      rankInfo &&
                      rankInfo.tournamentrankinfo &&
                      rankInfo.tournamentrankinfo.entrycnt | comma
                    }}<small
                      >/{{
                        rankInfo &&
                        rankInfo.tournamentrankinfo &&
                        rankInfo.tournamentrankinfo.entrylimit
                          | comma
                          | transToValue("∞")
                      }}</small
                    >
                  </h2>
                  <p>{{ $t("text.common.no_of_play") }}</p>
                </li>
              </ul>
            </div>
          </div>

          <div id="mytour-tabContent">
            <div
              class="tab-pane fade show active"
              id="mytour-1"
              role="tabpanel"
              aria-labelledby="mytour-1-tab"
            >
              <table class="table board">
                <thead>
                  <tr v-if="searchData.scoretype === 1">
                    <th class="d-lg-table-cell">
                      {{ $t("title.common.ranking") }}
                    </th>
                    <th class="d-lg-table-cell">
                      {{ $t("title.common.nickname") }}
                    </th>
                    <th class="d-none d-lg-table-cell">
                      {{ $t("title.common.location") }}
                    </th>
                    <th class="d-none d-lg-table-cell">
                      {{ $t("title.common.course") }}
                    </th>
                    <th class="d-none d-sm-table-cell">
                      {{ $t("title.common.stroke_score") }}
                    </th>
                    <th class="d-none d-lg-table-cell">
                      {{ $t("title.common.no_of_play") }}
                    </th>
                    <th class="th-arrow"></th>
                  </tr>
                  <tr v-else-if="searchData.scoretype === 2">
                    <th class="d-lg-table-cell">
                      {{ $t("title.common.ranking") }}
                    </th>
                    <th class="d-lg-table-cell">
                      {{ $t("title.common.nickname") }}
                    </th>
                    <th class="d-none d-lg-table-cell">
                      {{ $t("title.common.location") }}
                    </th>
                    <th class="d-none d-lg-table-cell">
                      {{ $t("title.common.course") }}
                    </th>
                    <th class="d-none d-sm-table-cell">
                      {{ $t("title.common.low_net_score") }} /
                      {{ $t("title.common.handi_score") }}
                    </th>
                    <th class="d-none d-lg-table-cell">
                      {{ $t("title.common.no_of_play") }}
                    </th>
                    <!-- <th class="d-none d-lg-table-cell">
                      Final Score<br>(Correction value)
                    </th> -->
                    <th class="th-arrow"></th>
                  </tr>
                  <tr v-else-if="searchData.scoretype === 3">
                    <th class="d-lg-table-cell">
                      {{ $t("title.common.ranking") }}
                    </th>
                    <th class="d-lg-table-cell">
                      {{ $t("title.common.nickname") }}
                    </th>
                    <th class="d-none d-lg-table-cell">
                      {{ $t("title.common.location") }}
                    </th>
                    <th class="d-none d-lg-table-cell">
                      {{ $t("title.common.course") }}
                    </th>
                    <th class="d-none d-sm-table-cell">
                      {{ $t("title.common.new_perio_score") }}
                    </th>
                    <th class="d-none d-lg-table-cell">
                      {{ $t("title.common.no_of_play") }}
                    </th>
                    <!-- <th class="d-none d-lg-table-cell">
                      Final Score<br>(Correction value)
                    </th> -->
                    <th class="th-arrow"></th>
                  </tr>
                  <tr v-else-if="searchData.scoretype === 4">
                    <th class="d-lg-table-cell">
                      {{ $t("title.common.ranking") }}
                    </th>
                    <th class="d-lg-table-cell">
                      {{ $t("title.common.nickname") }}
                    </th>
                    <th class="d-none d-lg-table-cell">
                      {{ $t("title.common.location") }}
                    </th>
                    <th class="d-none d-lg-table-cell">
                      {{ $t("title.common.course") }}
                    </th>
                    <th class="d-none d-sm-table-cell">
                      {{ $t("title.common.net_score") }}
                    </th>
                    <th class="d-none d-lg-table-cell">
                      {{ $t("title.common.no_of_play") }}
                    </th>
                    <!-- <th class="d-none d-lg-table-cell">
                      Final Score<br>(Correction value)
                    </th> -->
                    <th class="th-arrow"></th>
                  </tr>
                </thead>

                <tbody v-if="total_cnt > 0 && searchData.scoretype === 1">
                  <template v-for="(item, index) in items">
                    <tr :key="index">
                      <td class="d-lg-table-cell">
                        {{ item.tournamentrankinfo.rank }}
                      </td>
                      <td class="d-lg-table-cell">
                        <MemberInfo
                          :nickname="item.tournamentrankinfo.nickname"
                          :playerno="item.tournamentrankinfo.playerno"
                        />
                      </td>
                      <td class="d-none d-lg-table-cell shop-name">
                        {{
                          item.tournamentrankinfo.shopinfo &&
                          item.tournamentrankinfo.shopinfo.shopname
                        }}
                      </td>
                      <td class="d-none d-lg-table-cell">
                        <template v-for="(course, index_n) in item.coursescore">
                          <p :key="index_n + 'l1'">
                            {{ course.coursename }}
                          </p>
                        </template>
                      </td>
                      <td class="d-none d-sm-table-cell">
                        <template v-for="(course, index_n) in item.coursescore">
                          <p :key="index_n + 'l1'">
                            {{ course.strokescore }}
                          </p>
                        </template>
                      </td>
                      <td class="d-none d-lg-table-cell text-red">
                        {{ item.tournamentrankinfo.entrycnt }} /
                        {{
                          item.tournamentrankinfo.entrylimit | transToValue("∞")
                        }}
                      </td>
                      <td class="td-arrow" @click="toggle_tr(index)">
                        <img
                          src="/img/arrow_none_collapsed.png"
                          :class="{ 'btn-toggle-arrow': view_hide === index }"
                        />
                      </td>
                    </tr>
                    <tr
                      class="d-lg-none"
                      :class="{ show: view_hide === index }"
                      :key="index + 'r'"
                      v-if="view_hide === index"
                    >
                      <td colspan="6">
                        <div class="toggle-content">
                          <div class="row">
                            <div class="col-6">
                              {{ $t("title.common.location") }}
                            </div>
                            <div class="col-6 shop-name">
                              {{
                                item.tournamentrankinfo.shopinfo &&
                                item.tournamentrankinfo.shopinfo.shopname
                              }}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              {{ $t("title.common.course") }}
                            </div>
                            <div class="col-6">
                              <template
                                v-for="(course, index_nm) in item.coursescore"
                              >
                                {{ index_nm > 0 ? " / " : "" }}
                                <span :key="index_nm + 'm'"
                                  >{{ course.coursename }}
                                </span>
                              </template>
                            </div>
                          </div>

                          <div class="row d-sm-none">
                            <div class="col-6">
                              {{ $t("title.common.stroke_score") }}
                            </div>
                            <div class="col-6">
                              <template
                                v-for="(course, index_nm) in item.coursescore"
                              >
                                {{ index_nm > 0 ? " / " : "" }}
                                <span :key="index_nm + 'm'">
                                  {{ course.strokescore }}</span
                                >
                              </template>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              {{ $t("title.common.no_of_play") }}
                            </div>
                            <div class="col-6 text-red">
                              {{ item.tournamentrankinfo.entrycnt | comma }} /
                              {{
                                item.tournamentrankinfo.entrylimit
                                  | transToValue("∞")
                              }}
                            </div>
                          </div>
                          <!-- <div class="row">
                    <div class="col-6">Final Score<br>(Correction value)</div>
                    <div class="col-6">
                      {{ item.tournamentrankinfo.newperiolastscore | comma }}
                    </div>
                  </div> -->
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
                <tbody v-else-if="total_cnt > 0 && searchData.scoretype === 2">
                  <template v-for="(item, index) in items">
                    <tr :key="index">
                      <td class="d-lg-table-cell">
                        {{ item.tournamentrankinfo.rank }}
                      </td>
                      <td class="d-lg-table-cell">
                        <MemberInfo
                          :nickname="item.tournamentrankinfo.nickname"
                          :playerno="item.tournamentrankinfo.playerno"
                        />
                      </td>
                      <td class="d-none d-lg-table-cell shop-name">
                        {{
                          item.tournamentrankinfo.shopinfo &&
                          item.tournamentrankinfo.shopinfo.shopname
                        }}
                      </td>
                      <td class="d-none d-lg-table-cell">
                        <template v-for="(course, index_n) in item.coursescore">
                          <p :key="index_n + 'l1'">
                            {{ course.coursename }}
                          </p>
                        </template>
                      </td>
                      <td class="d-none d-sm-table-cell">
                        <template v-for="(course, index_n) in item.coursescore">
                          <p :key="index_n + 'l1'">
                            {{ course.low_net }} /
                            {{
                              Math.sign(course.min_score - course.low_net) > 0
                                ? "+"
                                : ""
                            }}
                            {{ course.min_score - course.low_net }}
                          </p>
                        </template>
                      </td>
                      <td class="d-none d-lg-table-cell text-red">
                        {{ item.tournamentrankinfo.entrycnt | comma }} /
                        {{
                          item.tournamentrankinfo.entrylimit | transToValue("∞")
                        }}
                      </td>
                      <!-- <td class="d-none d-lg-table-cell">
                {{ item.tournamentrankinfo.newperiolastscore | comma }}
              </td> -->
                      <td class="td-arrow" @click="toggle_tr(index)">
                        <img
                          src="/img/arrow_none_collapsed.png"
                          :class="{ 'btn-toggle-arrow': view_hide === index }"
                        />
                      </td>
                    </tr>
                    <tr
                      class="d-lg-none"
                      :class="{ show: view_hide === index }"
                      :key="index + 'r'"
                      v-if="view_hide === index"
                    >
                      <td colspan="6">
                        <div class="toggle-content">
                          <div class="row">
                            <div class="col-6">
                              {{ $t("title.common.location") }}
                            </div>
                            <div class="col-6 shop-name">
                              {{
                                item.tournamentrankinfo.shopinfo &&
                                item.tournamentrankinfo.shopinfo.shopname
                              }}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              {{ $t("title.common.course") }}
                            </div>
                            <div class="col-6">
                              <template
                                v-for="(course, index_n) in item.coursescore"
                              >
                                <p :key="index_n + 'l1'">
                                  {{ course.coursename }}
                                </p>
                              </template>
                            </div>
                          </div>
                          <div class="row d-sm-none">
                            <div class="col-6">
                              {{ $t("title.common.low_net_score") }} /
                              {{ $t("title.common.handi_score") }}
                            </div>
                            <div class="col-6">
                              <template
                                v-for="(course, index_n) in item.coursescore"
                              >
                                <p :key="index_n + 'l1'">
                                  {{ course.low_net }} /
                                  {{
                                    Math.sign(
                                      course.min_score - course.low_net
                                    ) > 0
                                      ? "+"
                                      : ""
                                  }}
                                  {{ course.min_score - course.low_net }}
                                </p>
                              </template>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              {{ $t("title.common.no_of_play") }}
                            </div>
                            <div class="col-6 text-red">
                              {{ item.tournamentrankinfo.entrycnt | comma }} /
                              {{
                                item.tournamentrankinfo.entrylimit
                                  | transToValue("∞")
                              }}
                            </div>
                          </div>
                          <!-- <div class="row">
                    <div class="col-6">Final Score<br>(Correction value)</div>
                    <div class="col-6">
                      {{ item.tournamentrankinfo.newperiolastscore | comma }}
                    </div>
                  </div> -->
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
                <tbody v-else-if="total_cnt > 0 && searchData.scoretype === 3">
                  <template v-for="(item, index) in items">
                    <tr :key="index">
                      <td class="d-lg-table-cell">
                        {{ item.tournamentrankinfo.rank }}
                      </td>
                      <td class="d-lg-table-cell">
                        <MemberInfo
                          :nickname="item.tournamentrankinfo.nickname"
                          :playerno="item.tournamentrankinfo.playerno"
                        />
                      </td>
                      <td class="d-none d-lg-table-cell shop-name">
                        {{
                          item.tournamentrankinfo.shopinfo &&
                          item.tournamentrankinfo.shopinfo.shopname
                        }}
                      </td>
                      <td class="d-none d-lg-table-cell">
                        <template v-for="(course, index_n) in item.coursescore">
                          <p :key="index_n + 'l1'">{{ course.coursename }}</p>
                        </template>
                      </td>
                      <td class="d-none d-sm-table-cell">
                        <template v-for="(course, index_n) in item.coursescore">
                          <p :key="index_n + 'l2'">
                            {{ course.newperioscore }}
                          </p>
                        </template>
                      </td>
                      <td class="d-none d-lg-table-cell text-red">
                        {{ item.tournamentrankinfo.entrycnt | comma }} /
                        {{
                          item.tournamentrankinfo.entrylimit | transToValue("∞")
                        }}
                      </td>
                      <!-- <td class="d-none d-lg-table-cell">
                {{ item.tournamentrankinfo.newperiolastscore | comma }}
              </td> -->
                      <td class="td-arrow" @click="toggle_tr(index)">
                        <img
                          src="/img/arrow_none_collapsed.png"
                          :class="{ 'btn-toggle-arrow': view_hide === index }"
                        />
                      </td>
                    </tr>
                    <tr
                      class="d-lg-none"
                      :class="{ show: view_hide === index }"
                      :key="index + 'r'"
                      v-if="view_hide === index"
                    >
                      <td colspan="6">
                        <div class="toggle-content">
                          <div class="row">
                            <div class="col-6">
                              {{ $t("title.common.location") }}
                            </div>
                            <div class="col-6 shop-name">
                              {{
                                item.tournamentrankinfo.shopinfo &&
                                item.tournamentrankinfo.shopinfo.shopname
                              }}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              {{ $t("title.common.course") }}
                            </div>
                            <div class="col-6">
                              <template
                                v-for="(course, index_nm) in item.coursescore"
                              >
                                {{ index_nm > 0 ? " / " : "" }}
                                <div :key="index_nm + 'm'">
                                  {{ course.coursename }}
                                </div>
                              </template>
                            </div>
                          </div>
                          <div class="row d-sm-none">
                            <div class="col-6">
                              {{ $t("title.common.new_perio_score") }}
                            </div>
                            <div class="col-6">
                              <template
                                v-for="(course, index_n) in item.coursescore"
                              >
                                <div :key="index_n + 'l2'">
                                  {{ course.newperioscore }}
                                </div>
                              </template>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              {{ $t("title.common.no_of_play") }}
                            </div>
                            <div class="col-6 text-red">
                              {{ item.tournamentrankinfo.entrycnt | comma }} /
                              {{
                                item.tournamentrankinfo.entrylimit
                                  | transToValue("∞")
                              }}
                            </div>
                          </div>
                          <!-- <div class="row">
                    <div class="col-6">Final Score<br>(Correction value)</div>
                    <div class="col-6">
                      {{ item.tournamentrankinfo.newperiolastscore | comma }}
                    </div>
                  </div> -->
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
                <tbody v-else-if="total_cnt > 0 && searchData.scoretype === 4">
                  <template v-for="(item, index) in items">
                    <tr :key="index">
                      <td class="d-lg-table-cell">
                        {{ item.tournamentrankinfo.rank }}
                      </td>
                      <td class="d-lg-table-cell">
                        <MemberInfo
                          :nickname="item.tournamentrankinfo.nickname"
                          :playerno="item.tournamentrankinfo.playerno"
                        />
                      </td>
                      <td class="d-none d-lg-table-cell shop-name">
                        {{
                          item.tournamentrankinfo.shopinfo &&
                          item.tournamentrankinfo.shopinfo.shopname
                        }}
                      </td>
                      <td class="d-none d-lg-table-cell">
                        <template v-for="(course, index_n) in item.coursescore">
                          <p :key="index_n + 'l1'">
                            {{ course.coursename }}
                          </p>
                        </template>
                      </td>
                      <td class="d-none d-sm-table-cell">
                        <template v-for="(course, index_n) in item.coursescore">
                          <p :key="index_n + 'l1'">
                            {{
                              (course.avghandicapscore - item.base_par)
                                | toFixed
                            }}
                          </p>
                        </template>
                      </td>

                      <td class="d-none d-lg-table-cell text-red">
                        {{ item.tournamentrankinfo.entrycnt | comma }} /
                        {{
                          item.tournamentrankinfo.entrylimit | transToValue("∞")
                        }}
                      </td>
                      <!-- <td class="d-none d-lg-table-cell">
                {{ item.tournamentrankinfo.newperiolastscore | comma }}
              </td> -->
                      <td class="td-arrow" @click="toggle_tr(index)">
                        <img
                          src="/img/arrow_none_collapsed.png"
                          :class="{ 'btn-toggle-arrow': view_hide === index }"
                        />
                      </td>
                    </tr>
                    <tr
                      class="d-lg-none"
                      :class="{ show: view_hide === index }"
                      :key="index + 'r'"
                      v-if="view_hide === index"
                    >
                      <td colspan="6">
                        <div class="toggle-content">
                          <div class="row">
                            <div class="col-6">
                              {{ $t("title.common.location") }}
                            </div>
                            <div class="col-6 shop-name">
                              {{
                                item.tournamentrankinfo.shopinfo &&
                                item.tournamentrankinfo.shopinfo.shopname
                              }}
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              {{ $t("title.common.course") }}
                            </div>
                            <div class="col-6">
                              <template
                                v-for="(course, index_n) in item.coursescore"
                              >
                                <p :key="index_n + 'l1'">
                                  {{ course.coursename }}
                                </p>
                              </template>
                            </div>
                          </div>
                          <div class="row d-sm-none">
                            <div class="col-6">
                              {{ $t("title.common.net_score") }}
                            </div>
                            <div class="col-6">
                              <template
                                v-for="(course, index_n) in item.coursescore"
                              >
                                <p :key="index_n + 'l1'">
                                  {{
                                    (course.avghandicapscore - item.base_par)
                                      | toFixed
                                  }}
                                </p>
                              </template>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-6">
                              {{ $t("title.common.no_of_play") }}
                            </div>
                            <div class="col-6 text-red">
                              {{ item.tournamentrankinfo.entrycnt | comma }} /
                              {{
                                item.tournamentrankinfo.entrylimit
                                  | transToValue("∞")
                              }}
                            </div>
                          </div>
                          <!-- <div class="row">
                    <div class="col-6">Final Score<br>(Correction value)</div>
                    <div class="col-6">
                      {{ item.tournamentrankinfo.newperiolastscore | comma }}
                    </div>
                  </div> -->
                        </div>
                      </td>
                    </tr>
                  </template>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td
                      colspan="6"
                      class="text-center"
                      v-if="
                        tournamentinfo.holecount > 9 || searchData.scoretype < 3
                      "
                    >
                      {{ $t("text.common.no_data") }}
                    </td>
                    <td colspan="6" class="text-center" v-else>
                      {{ $t("text.common.not_provided_9_hole_mode") }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <b-pagination-nav
                :link-gen="linkGen"
                :number-of-pages="page_total"
                v-model="searchData.subpageno"
                use-router
                aria-label="Page navigation"
                v-if="page_total > 1"
                first-class="prev"
                prev-class="prev"
                next-class="next"
                last-class="next"
                hide-ellipsis
                limit="10"
              >
                <template #first-text>
                  <i class="material-icons">keyboard_double_arrow_left</i>
                </template>
                <template #prev-text>
                  <i class="material-icons">navigate_before</i>
                </template>
                <template #next-text>
                  <i class="material-icons">navigate_next</i>
                </template>
                <template #last-text>
                  <i class="material-icons">keyboard_double_arrow_right</i>
                </template>
                <template #page="{ page, active }">
                  <a v-if="active">{{ page }}</a>
                  <a v-else>{{ page }}</a>
                </template>
              </b-pagination-nav>

              <div class="btn-bottom-wrapper">
                <b-button
                  size="xl"
                  variant="outline-primary"
                  :aria-label="$t('button.common.list')"
                  @click="
                    $router.push({ name: 'MyTournaments' }).catch(() => {})
                  "
                >
                  {{ $t("button.common.list") }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import location from "@/components/Location";
import tab from "@/components/membership/Tab";
import MemberInfo from "@/components/membership/MemberInfo";
import ax from "@/api/membership";
import tax from "@/api/tournament";
import "@/filter/common";
import { myMixin } from "@/mixin/myMixin";

export default {
  name: "MyTournamentsRanking",
  components: {
    location,
    tab,
    MemberInfo,
  },
  data() {
    return {
      searchData: {
        subpageno: 1,
        tournamentno: this.$route.params.id,
        scoretype:
          this.$route.query.scoretype ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].scoretype) ||
          1,
        gender:
          this.$route.query.gender ||
          (this.$store.state.searchData[this.$route.name] &&
            this.$store.state.searchData[this.$route.name].gender) ||
          0,
      },
      page_total: 1,
      total_cnt: 0,

      rows: 10,
      items: [],
      rankInfo: [],
      view_hide: null,
      tournamentinfo: {},
      member: {
        playerno: this.$route.params.playerno,
        playerbasicinfo: [],
        playerpalyinfo: [],
      },
    };
  },
  methods: {
    get_membertournamentranklist() {
      this.view_hide = null;
      this.$store.commit("setSearchData", {
        [this.$route.name]: this.searchData,
      });
      ax.get_membertournamentranklist(
        this.searchData,
        (flag, data, code = "") => {
          // console.log(flag, data);
          if (flag) {
            this.total_cnt = data.tournamentranklistcnt;
            this.page_total = Math.ceil(data.tournamentranklistcnt / this.rows);
            this.items = data.tournamentranklist;

            this.rankInfo = data.player;

            this.tournamentinfo = data.tournamentinfo;
          } else {
            this.page_total = 0;
            // console.log(this.searchData.scoretype);

            if (code == "300") {
              this.$notify({
                group: "alert",
                type: "warn",
                text: data,
                duration: 5000,
                speed: 1000,
              });
            }
            // alert(data);
          }
        }
      );
    },
    linkGen(pageNum) {
      return `?page=${pageNum}`;
    },
    toggle_tr(index) {
      if (this.view_hide === index) this.view_hide = null;
      else this.view_hide = index;
    },
    onSubmit(event) {
      event.preventDefault();
      this.searchData.subpageno = 1;
      this.$router
        .push({
          query: {
            scoretype: this.searchData.scoretype,
            gender: this.searchData.gender,
          },
        })
        .catch(() => {});
    },
    change_type(type) {
      this.searchData.scoretype = type;
      // this.view_hide = null;
      // this.searchData.pageno = 1;
      // this.view_hide = null;
      // this.$router
      //   .push({
      //     name: "MyTournamentsRanking",
      //     params: { id: this.searchData.tournamentno },
      //     query: {
      //       scoretype: this.searchData.scoretype,
      //       gender: this.searchData.gender,
      //     },
      //   })
      //   .catch(() => {});
    },
    getMemberInfo(playerno) {
      tax.get_playerinfo(playerno, (flag, data) => {
        if (flag) {
          this.member.playerno = playerno;
          this.member.playerbasicinfo = data.playerbasicinfo;
          this.member.playerpalyinfo = data.playerpalyinfo;
          this.$refs["modal-info-member"].modalOuterStyle.zIndex = 9999;
          this.$refs["modal-info-member"].show();
        } else {
          alert(data);
        }
      });
    },
  },
  created() {
    this.get_membertournamentranklist();
  },
  watch: {
    "searchData.scoretype": function () {
      this.searchData.subpageno = 1;
      this.get_membertournamentranklist();
    },
    "searchData.gender": function () {
      this.searchData.subpageno = 1;
      this.get_membertournamentranklist();
    },

    "searchData.subpageno": function () {
      this.get_membertournamentranklist();
    },
  },
  mixins: [myMixin],
};
</script>
